import React, { useState } from "react";
import "./Login.css";
import userService from "../../../services/user";
import { encryptedUserData } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";

function Login() {
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user.username) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        username: "Vui lòng nhập tên đăng nhập",
      }));
    }
    if (!user.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Vui lòng nhập mật khẩu",
      }));
    }
    if (user.username && user.password) {
      try {
        const response = await userService.login(user);
        encryptedUserData(response, user);
        navigate("/admin");
        console.log(response);
      } catch (error) {
        alert("Tên tài khoản hoặc mật khẩu không đúng, vui lòng nhập lại!");
        console.log(error);
      }
    }
  };

  return (
    <div className="login">
      <div className="login-form">
        <h1>Đăng nhập</h1>
        <label>Tên đăng nhập</label>
        <input
          type="text"
          name="username"
          placeholder="Nhập tên đăng nhập"
          value={user.username}
          onChange={handleChange}
          className={errors.username && "error"}
        />
        {errors.username && <p className="error-msg">{errors.username}</p>}
        <label>Mật khẩu</label>
        <input
          type="password"
          name="password"
          placeholder="Nhập mật khẩu"
          value={user.password}
          onChange={handleChange}
          className={errors.password && "error"}
        />
        {errors.password && <p className="error-msg">{errors.password}</p>}
        <button type="submit" className="btn" onClick={handleSubmit}>
          Đăng nhập
        </button>
      </div>
    </div>
  );
}

export default Login;
