import React from "react";
import "./HomeHeroHeader.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const img = [
  "https://images.unsplash.com/photo-1712928247899-2932f4c7dea3?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=2952&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187748397_6b642e0c38f8abccf59d1e5832b12c6a.jpg?alt=media&token=91f366fc-09ce-4e29-9c0a-fe2e0540a59e",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187748604_ce3b051222d9d24ae80407a658c6a93c.jpg?alt=media&token=59d8d6d6-2400-4460-8c64-5c3f950777f0",
];

function HomeHeroHeader() {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {img.map((item, index) => (
        <SwiperSlide key={index}>
          <img src={item} alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default HomeHeroHeader;
