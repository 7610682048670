import api from "./api";

const handleApiError = (error) => {
  console.error(error);
  throw new Error(error.response?.data?.error || "Lỗi!");
};

const getAllNews = async () => {
  try {
    const response = await api.get("/api/news");
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const deleteNews = async (id) => {
  try {
    const response = await api.delete(`/api/news/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const getNewsById = async (id) => {
  try {
    const response = await api.get(`/api/news/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const updateNews = async (id, data) => {
  try {
    const response = await api.put(`/api/news/${id}`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const createNews = async (data) => {
  try {
    const response = await api.post("/api/news", data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const newsService = {
  getAllNews,
  deleteNews,
  getNewsById,
  updateNews,
  createNews,
};

export default newsService;
