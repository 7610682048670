import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./About.css";

function About() {
  return (
    <MainLayout>
      <div className="about">
        <div className="container">
          <div className="about-hero">
            <h1>Giới thiệu tổng quát</h1>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349191349335_28b61678cd58e205996c3436daecefba.jpg?alt=media&token=b451b9a9-52cb-4ffb-b090-87c9cb23c857"
              alt=""
            />
          </div>
          <div className="about-section">
            <p className="bold">
              Công ty Xây dựng Quân Bảo là một trong những đơn vị thiết kế, thi
              công và xây dựng các công trình nhà dân dụng và công nghiệp.
            </p>
            <p>
              Với kinh nghiệm nhiều năm hoạt động trong ngành, chúng tôi tự hào
              mang đến khách hàng dịch vụ chất lượng, đội ngũ nhân viên chuyên
              nghiệp và sự cam kết về sự hoàn hảo trong mọi chi tiết.
            </p>
            <p>Dịch vụ của chúng tôi bao gồm:</p>
            <ol style={{ marginLeft: 50 }}>
              <li>
                Thiết kế kiến trúc nội ngoại thất: Chúng tôi có đội ngũ kiến
                trúc sư tài năng, giàu kinh nghiệm trong việc thiết kế các loại
                công trình như nhà cấp 4, nhà phố, biệt thự. Chúng tôi cũng
                thiết kế cho các công trình làm việc như nhà xưởng, cửa hàng,
                văn phòng, đảm bảo tính thẩm mỹ và sự tiện nghi cho từng không
                gian.
              </li>
              <li>
                Thi công xây nhà trọn gói: Chúng tôi cam kết mang lại dịch vụ
                xây dựng chất lượng, đảm bảo tiến độ và mức chi phí hợp lý. Dù
                là xây nhà cấp 4, nhà phố hay biệt thự, chúng tôi luôn đặt chất
                lượng công trình lên hàng đầu.
              </li>
              <li>
                Sửa chữa nhà trọn gói: Bên cạnh việc xây mới, chúng tôi cũng
                cung cấp dịch vụ sửa chữa, nâng cấp và cải tạo các công trình đã
                tồn tại. Từ nhà cũ, nhà xưởng đến nhà ở, chúng tôi cam kết mang
                lại sự mới mẻ, tiện nghi và hiệu quả cho mọi công trình.
              </li>
              <li>
                Giám sát công trình thi công: Chúng tôi cung cấp dịch vụ giám
                sát chặt chẽ từng bước thi công, đảm bảo mọi công đoạn diễn ra
                một cách suôn sẻ và đúng tiến độ, từ việc chuẩn bị vật liệu đến
                hoàn thiện công trình.
              </li>
            </ol>
            <p className="bold">
              Với sứ mệnh "UY TÍN VỮNG CHẮC - XÂY DỰNG ĐẲNG CẤP", Công ty Xây
              dựng Quân Bảo cam kết đồng hành cùng khách hàng, mang lại những
              giải pháp tối ưu nhất cho mọi nhu cầu xây dựng của quý vị.
            </p>
            <div className="image-fuild">
              <img
                src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713779055/sek9hwpzzkt0gngvtui6.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default About;
