import React from "react";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import "./AdminLayout.css";

function AdminLayout({ children }) {
  return (
    <>
      <AdminHeader />
      {children}
    </>
  );
}

export default AdminLayout;
