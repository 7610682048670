import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

function SuaChua() {
  return (
    <MainLayout>
      <div className="quote">
        <div className="container">
          <h1>Cải tạo và sửa chữa nhà</h1>
          <p>
            Sau một thời gian sử dụng, các công trình xây dựng không tránh khỏi
            việc xuống cấp và hư hỏng. Đặc biệt là những ngôi nhà đã tồn tại
            trong nhiều năm, việc cải tạo và sửa chữa trở nên cực kỳ cần thiết
            để đáp ứng các nhu cầu sinh hoạt của gia đình.
          </p>
          <p>
            Dịch vụ cải tạo và sửa chữa nhà trọn gói giúp nâng cấp không gian
            sống, tạo ra một ngôi nhà thẩm mỹ và bền đẹp hơn. Chúng tôi cam kết
            mang đến cho quý vị giải pháp toàn diện và hiệu quả nhất để gia cố
            và làm mới ngôi nhà của mình.
          </p>
          <p>
            Để giúp quý vị dễ dàng trong việc lựa chọn, chúng tôi xin gửi đến
            bảng báo giá dịch vụ cải tạo, sửa chữa nhà ở trọn gói của đơn vị để
            tham khảo. Hãy đến với dịch vụ sửa chữa, cải tạo nhà của chúng tôi
            để được nhận sự tư vấn từ những nhân viên có kinh nghiệm và nhiệt
            huyết.
          </p>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605401/loyovbkqu3b01unkczoa.jpg"
              alt=""
            />
          </div>
          <p className="bold">Các Hạng Mục Cải Tạo và Sửa Chữa Nhà Trọn Gói</p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>Đập phá tường</li>
            <li>Đục phá nền gạch, nền bê tông</li>
            <li>Đục phá gạch tường, gạch nhà vệ sinh</li>
            <li>Tháo dỡ mái tôn</li>
            <li>Tháo dỡ trần thạch cao, vách thạch cao, la phong</li>
            <li>Tháo bỏ cửa sổ, cửa chính, cổng</li>
            <li>Tháo bỏ giường, tủ, đồ nội thất, đồ trang trí</li>
          </ol>
          <p className="bold">Phương Thức Báo Giá:</p>
          <p>
            Đơn giá phụ thuộc vào thực tế (báo giá theo bộ, theo m2, theo gói,
            theo khối,...)
          </p>
          <p className="bold">
            Bảng Báo Giá Chi Tiết Dịch Vụ Sửa Nhà Trọn Gói:{" "}
          </p>
          <p>
            Nếu Quý khách hàng đang có nhu cầu sử dụng dịch vụ sửa chữa nhà trọn
            gói, cải tạo và nâng cấp, hoặc muốn chuyển đổi công năng mục đích sử
            dụng của ngôi nhà, và cần thêm thông tin về giá cả hiện nay, hãy
            cùng chúng tôi cập nhật đơn giá ngay sau đây.
          </p>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1714043201/xmfg8dlrsyhnmb9rkwys.png"
              alt=""
            />
          </div>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605409/hswwsyoc02vnv9e9dphi.jpg"
              alt=""
            />
          </div>
          <p className="bold">
            Lý do nên chọn dịch vụ sửa nhà trọn gói của chúng tôi :
          </p>
          <p>
            Tiến độ thi công nhanh chóng: Chúng tôi cam kết thực hiện các dự án
            sửa nhà trọn gói một cách nhanh chóng và hiệu quả. Đội ngũ kỹ thuật
            của chúng tôi được huấn luyện để làm việc hiệu quả và tuân thủ một
            lịch trình công việc chặt chẽ, giúp hoàn thành dự án đúng tiến độ.
            Đồng thời, chúng tôi cũng sử dụng các phương pháp thi công hiện đại
            và công nghệ tiên tiến để tối ưu hóa thời gian thi công mà vẫn đảm
            bảo chất lượng công trình. Chúng tôi hiểu rằng việc hoàn thành dự án
            đúng hạn là rất quan trọng đối với quý khách hàng, và chúng tôi luôn
            nỗ lực để đáp ứng và vượt qua kỳ vọng của khách hàng.
          </p>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605408/qxetvf3kfe2xudszhu16.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605222/eziuqnfed7dvoa1aitq3.jpg"
              alt=""
            />
          </div>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605071/zze4xrbff3vnxtacwbog.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default SuaChua;
