import React, { useEffect, useState } from "react";
import { isAuthenticated } from "../../../utils/auth";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import { Link } from "react-router-dom";
import projectService from "../../../services/project";
import DataTable from "../../components/DataTable/DataTable";

function AdminProjects() {
  const isLoggedIn = isAuthenticated();

  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await projectService.getAllProjects();
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [projects]);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredProjects = currentProjects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return isLoggedIn ? (
    <AdminLayout>
      <div className="admin-container">
        <div className="admin-content">
          <div className="admin-content-header">
            <h1>Dự án</h1>
            <Link to="/admin/du-an/them">
              <button className="btn">Tạo mới</button>
            </Link>
          </div>
          <input
            type="text"
            placeholder="Tìm kiếm dự án..."
            onChange={handleSearch}
            className="searchInData"
          />
          <table style={{ padding: "10px" }}>
            <DataTable data={filteredProjects} type="duan" />
          </table>
          <ul className="pagination">
            {Array.from({
              length: Math.ceil(projects.length / projectsPerPage),
            }).map((_, index) => (
              <li key={index} className="page-item">
                <button
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AdminLayout>
  ) : (
    <ErrorPage />
  );
}

export default AdminProjects;
