import api from "./api";

const handleApiError = (error) => {
  console.error(error);
  throw new Error(error.response?.data?.error || "Lỗi!");
};

const getAllProjects = async () => {
  try {
    const response = await api.get("/api/projects");
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const deleteProject = async (id) => {
  try {
    const response = await api.delete(`/api/projects/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const getProjectById = async (id) => {
  try {
    const response = await api.get(`/api/projects/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const updateProject = async (id, data) => {
  try {
    const response = await api.put(`/api/projects/${id}`, data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const createProject = async (data) => {
  try {
    const response = await api.post("/api/projects", data);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const projectService = {
  getAllProjects,
  deleteProject,
  getProjectById,
  updateProject,
  createProject,
};

export default projectService;
