import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import PriceQuote from "./pages/PriceQuote/PriceQuote";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import Login from "./Admin/pages/Login/Login";
import AdminDashboard from "./Admin/pages/AdminDashboard/AdminDashboard";
import AdminProjects from "./Admin/pages/AdminProjects/AdminProjects";
import AdminAddProject from "./Admin/pages/AdminProjects/AdminAddProject";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import AdminNews from "./Admin/pages/AdminNews/AdminNews";
import AdminAddNews from "./Admin/pages/AdminNews/AdminAddNews";
import News from "./pages/News/News";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import PhanTho from "./pages/PriceQuote/PhanTho";
import TronGoi from "./pages/PriceQuote/TronGoi";
import SuaChua from "./pages/PriceQuote/SuaChua";
import ThietKe from "./pages/PriceQuote/ThietKe";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gioi-thieu" element={<About />} />
        <Route path="/bao-gia" element={<PriceQuote />} />
        <Route path="/bao-gia/phan-tho" element={<PhanTho />} />
        <Route path="/bao-gia/tron-goi" element={<TronGoi />} />
        <Route path="/bao-gia/sua-chua" element={<SuaChua />} />
        <Route path="/bao-gia/thiet-ke" element={<ThietKe />} />
        <Route path="/du-an" element={<Projects />} />
        <Route path="/du-an/:id" element={<ProjectDetail />} />
        <Route path="/tin-tuc/" element={<News />} />
        <Route path="/tin-tuc/:id" element={<NewsDetail />} />

        <Route path="/lien-he" element={<Contact />} />

        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin/du-an" element={<AdminProjects />} />
        <Route path="/admin/du-an/them" element={<AdminAddProject />} />
        <Route path="/admin/tin-tuc" element={<AdminNews />} />
        <Route path="/admin/tin-tuc/them" element={<AdminAddNews />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
