import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { useParams } from "react-router-dom";
import projectService from "../../services/project";
import "./ProjectDetail.css";
import Loading from "../../components/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";

function ProjectDetail() {
  const { id } = useParams();
  const [project, setProject] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await projectService.getProjectById(id);
        setProject(response.data);
        setError(null);
      } catch (error) {
        setError("Không thể tải dự án. Vui lòng thử lại sau.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!project) {
    return <ErrorPage />;
  }

  return (
    <MainLayout>
      <div className="project-detail">
        <div className="container">
          <h1 className="project-name">{project.name}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: project.content }}
            className="project-content"
          />
        </div>
      </div>
    </MainLayout>
  );
}

export default ProjectDetail;
