import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import HomeHeroHeader from "../../components/HomeHeroHeader/HomeHeroHeader";
import "./Home.css";
import SliderVertical from "../../components/SliderVertical/SliderVertical";
import newsService from "../../services/news";
import { Link } from "react-router-dom";

const img = [
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187728984_dab7b05c1d998dcc4ec98ccd8fe0558b.jpg?alt=media&token=ebcdab71-a7ba-4056-8a0e-23ea92f1b729",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187735866_0fd604718fdeebe91c0586a9df71f1ae.jpg?alt=media&token=97d8b6ea-437c-424c-9072-52aff68a1276",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187735894_8f56393af0f5061753a553b5118aa85b.jpg?alt=media&token=c17b8b9a-7558-4ee8-b90b-fa8afae0cc3c",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187738323_87ad9107284b2a551a71a2652569c525.jpg?alt=media&token=f52ab3ed-4a1c-41d7-9a50-da543f3a3219",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187748177_657488f00ac45cb716e4ed8f3b6a3905.jpg?alt=media&token=7848169e-789a-44f2-afc1-e3154a237657",
  "https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/z5349187748317_66b27db7ca56cfbe37177049d19b4f54.jpg?alt=media&token=b13014dd-1e41-432c-8764-92a1584426f9",
];

function Home() {
  const [listNews, setListNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await newsService.getAllNews();
        const latestNews = response.data.slice(0, 4);
        setListNews(latestNews);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <MainLayout>
      <HomeHeroHeader />
      <div className="home-section">
        <div className="container">
          <div className="home-section-header">VỀ CHÚNG TÔI</div>
          <div className="home-section-content">
            <div className="home-section-content-header">
              CÔNG TY TNHH ĐẦU TƯ XÂY DỰNG QUÂN BẢO
            </div>
            <p className="home-section-content-body">
              Công ty TNHH Đầu Tư Xây Dựng Quân Bảo đã có một quá trình phát
              triển từng bước và là một địa chỉ uy tín trong lĩnh vực xây dựng
              và thiết kế. Với nền tảng kinh nghiệm và sự chuyên nghiệp, chúng
              tôi cam kết mang đến cho khách hàng những dịch vụ chất lượng và
              đáng tin cậy.
            </p>
            <p className="home-section-content-body">
              Chúng tôi chuyên cung cấp các dịch vụ sau:
            </p>
            <ol className="home-section-content-list">
              <li>
                Xây Dựng Công Trình: Từ nhà dân dụng nhỏ, nhà phố đến những dự
                án lớn như nhà xưởng và biệt thự, chúng tôi đều có đội ngũ kỹ
                thuật lành nghề và đầy đủ kinh nghiệm để đảm nhận mọi công việc
                xây dựng một cách chuyên nghiệp và hiệu quả.
              </li>
              <li>
                Sửa Chữa và Nâng Cấp Công Trình: Chúng tôi cung cấp dịch vụ sửa
                chữa và nâng cấp đa dạng cho mọi loại công trình, từ việc sửa
                nhỏ cho nhà ở đến việc nâng cấp toàn bộ công trình công nghiệp.
              </li>
              <li>
                Thiết Kế Công Trình: Đội ngũ kiến trúc sư của chúng tôi sẵn sàng
                tư vấn và thiết kế những công trình đẹp mắt, hiện đại và hợp
                phong thủy, đảm bảo đáp ứng mọi yêu cầu của khách hàng.
              </li>
              <li>
                Sơn Phủ Công Trình: Chúng tôi cung cấp dịch vụ sơn phủ chất
                lượng cao để bảo vệ và làm đẹp cho mọi công trình xây dựng.
              </li>
            </ol>
            <p className="home-section-content-body">
              Công ty TNHH Đầu Tư Xây Dựng Quân Bảo đã trải qua một quá trình
              phát triển vững chắc và không ngừng hoàn thiện từng ngày. Với mục
              tiêu mang lại những giải pháp xây dựng và thiết kế hiệu quả nhất
              cho khách hàng, chúng tôi đã và đang không ngừng nỗ lực và đầu tư
              vào nhân lực, công nghệ và quy trình làm việc để đáp ứng mọi nhu
              cầu của khách hàng.
            </p>
            <p className="home-section-content-body">
              Với đội ngũ kỹ sư, kiến trúc sư, nhà thầu và công nhân lành nghề
              và có kinh nghiệm, chúng tôi tự hào mang đến những sản phẩm xây
              dựng chất lượng, đẳng cấp và đáp ứng được mọi yêu cầu về thiết kế,
              chất lượng và tiến độ.
            </p>
            <p className="home-section-content-body">
              Không chỉ dừng lại ở việc cung cấp các dịch vụ cơ bản, chúng tôi
              còn đặc biệt chú trọng vào việc tư vấn và đề xuất các giải pháp
              tiết kiệm chi phí, hiệu quả về mặt kỹ thuật và thẩm mỹ cho khách
              hàng. Chúng tôi luôn lắng nghe và hiểu rõ nhu cầu của khách hàng
              để đưa ra những giải pháp phù hợp nhất.
            </p>
            <p className="home-section-content-body">
              Ngoài ra, việc chú trọng vào việc nâng cao chất lượng dịch vụ và
              mối quan hệ với khách hàng cũng là một trong những ưu tiên hàng
              đầu của chúng tôi. Chúng tôi luôn đề cao sự tin tưởng và sự hài
              lòng của khách hàng, và luôn sẵn sàng hỗ trợ và giải quyết mọi vấn
              đề phát sinh trong quá trình làm việc.
            </p>
            <p className="home-section-content-body">
              Tất cả những điều này cùng với cam kết về uy tín, chất lượng và sự
              chuyên nghiệp đã giúp Công ty TNHH Đầu Tư Xây Dựng Quân Bảo trở
              thành một đối tác đáng tin cậy và được khách hàng tin dùng trong
              lĩnh vực xây dựng và thiết kế.
            </p>
          </div>
          <div className="home-section-cards">
            <div className="home-section-card">
              <div className="home-section-card-header">
                <div className="home-section-card-header-img">
                  <img
                    src="https://www.cc1.vn/wp-content/uploads/2022/04/Group-15326.png"
                    alt=""
                  />
                </div>
                <div className="home-section-card-header-title">Uy tín</div>
              </div>
              <p className="home-section-card-body">
                Uy tín là trên hết, tận dụng mọi cơ hội để chứng minh sự chân
                thành và trung thực. Chúng tôi cam kết đáp ứng mọi yêu cầu của
                khách hàng và mang dự án đến thành công.
              </p>
            </div>
            <div className="home-section-card">
              <div className="home-section-card-header">
                <div className="home-section-card-header-img">
                  <img
                    src="https://www.cc1.vn/wp-content/uploads/2022/04/Group-14781.png"
                    alt=""
                  />
                </div>
                <div className="home-section-card-header-title">CHẤT LƯỢNG</div>
              </div>
              <p className="home-section-card-body">
                Chất lượng là ưu tiên hàng đầu, chúng tôi cam kết đảm bảo chất
                lượng tốt nhất và liên tục nâng cao tiêu chuẩn chất lượng để
                mang lại giá trị lâu dài.
              </p>
            </div>
            <div className="home-section-card">
              <div className="home-section-card-header">
                <div className="home-section-card-header-img">
                  <img
                    src="https://www.cc1.vn/wp-content/uploads/2022/04/Group-15327.png"
                    alt=""
                  />
                </div>
                <div className="home-section-card-header-title">HIỆU QUẢ</div>
              </div>
              <p className="home-section-card-body">
                Hiệu quả là trọng tâm của mọi hoạt động. Chúng tôi luôn tìm kiếm
                giải pháp hiệu quả nhất để đem lại lợi ích tối đa cho khách hàng
                thông qua sự tổ chức thông minh và quản lý công việc chặt chẽ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section">
        <div className="container">
          <div className="home-section-header">Dự án</div>
          <p className="home-section-header-desc">Các dự án đã thực hiện</p>
          <SliderVertical data={img} />
        </div>
      </div>
      <div className="home-section">
        <div className="container">
          <div className="home-section-header">Tin tức</div>
          <p className="home-section-header-desc">Các tin tức của công ty</p>
          <div className="projects-cards">
            {listNews.map((n, i) => (
              <Link
                to={`/tin-tuc/${n._id}`}
                style={{ textDecoration: "none", color: "#000" }}
              >
                <div className="projects-card">
                  <img className="projects-card-img" src={n.image} alt="" />
                  <div className="projects-card-name">
                    <p className="bold">{n.name}</p>
                    <button className="btn">Xem chi tiết</button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Home;
