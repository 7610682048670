import React from "react";
import "./Contact.css";
import MainLayout from "../../layouts/MainLayout/MainLayout";

function Contact() {
  return (
    <MainLayout>
      <div className="contact">
        <div className="container">
          <h1>Thông tin liên hệ</h1>
          <div className="contact-separate"></div>
          <h2>CÔNG TY TNHH ĐẦU TƯ XÂY DỰNG QUÂN BẢO</h2>
          <p>
            Địa chỉ: Số 451/30 đường Xô Viết Nghệ Tĩnh, Phường 26, Quận Bình
            Thạnh, Thành phố Hồ Chí Minh.
          </p>
          <p>Số điện thoại: 0974281309</p>
          <p>Email: xaydungquanbao@gmail.com</p>
          <div className="contact-separate" style={{ marginTop: 60 }}></div>
          <h2>Google Map</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d979.7651709853059!2d106.71076136968178!3d10.806664299334003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528bccc4052d9%3A0x1dde19ed31c664b2!2zNDUxIFjDtCBWaeG6v3QgTmdo4buHIFTEqW5oLCBQaMaw4budbmcgMjYsIELDrG5oIFRo4bqhbmgsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCA3MDAwMCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1713422284570!5m2!1sen!2s"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </div>
    </MainLayout>
  );
}

export default Contact;
