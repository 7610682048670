import React, { useEffect, useState } from "react";
import "../Projects/Projects.css";
import newsService from "../../services/news";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { Link } from "react-router-dom";

function News() {
  const [listNews, setListNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await newsService.getAllNews();
      setListNews(response.data);
    };
    fetchData();
  }, [listNews]);

  return (
    <MainLayout>
      <div className="projects">
        <div className="container">
          <h1>Tin tức</h1>
          <div className="projects-cards">
            {listNews.map((p, i) => (
              <Link to={`/tin-tuc/${p._id}`}>
                <div className="projects-card">
                  <img className="projects-card-img" src={p.image} alt="" />
                  <div className="projects-card-name">
                    <p className="bold">{p.name}</p>
                    <button className="btn">Xem chi tiết</button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default News;
