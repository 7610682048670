import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

function PhanTho() {
  return (
    <MainLayout>
      <div className="quote">
        <div className="container">
          <h1>Phần thô</h1>
          <p className="bold">
            Xây Nhà Phần Thô và Phần Hoàn Thiện: Hạng Mục Thi Công và Vật Liệu
            Sử Dụng
          </p>
          <p className="bold">Xây Nhà Phần Thô:</p>
          <p>
            Trong quá trình xây dựng phần thô của một ngôi nhà, các hạng mục thi
            công cụ thể bao gồm:
          </p>
          <ol style={{ paddingLeft: 50 }}>
            <li>
              Làm móng: Đào đất, san lấp, xây dựng móng bê tông để chịu lực của
              công trình.
            </li>
            <li>
              Xây tường: Thi công tường bằng gạch, xi măng hoặc vật liệu xây
              dựng khác để tạo nên khung cấu trúc của nhà.
            </li>
            <li>
              Lắp đặt hệ thống cốt thép: Đặt cốt thép và sắp xếp theo bản vẽ kỹ
              thuật để tạo ra khung cột, dầm của công trình.
            </li>
            <li>
              Làm mái: Xây dựng mái nhà bằng gỗ, thép hoặc vật liệu xây dựng
              khác để che phủ phần trên của công trình.
            </li>
            <li>
              Lắp đặt cửa, cửa sổ: Mở lỗ, lắp đặt cửa và cửa sổ để tạo ra các
              lối đi và nguồn sáng tự nhiên cho nhà.
            </li>
          </ol>
          <p>
            Về vật liệu sử dụng trong xây dựng phần thô, thông thường bao gồm:
          </p>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713778390/miharsbuvbu834hojhtv.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713778419/fgd2ikrededhnjhaf3vd.jpg"
              alt=""
            />
          </div>
          <ol style={{ paddingLeft: 50 }}>
            <li>
              Gạch, xi măng: Dùng để xây tường, móng và các cấu trúc chịu lực
              khác.
            </li>
            <li>
              Cốt thép: Sử dụng để gia cố cấu trúc, tạo độ bền cho công trình.
            </li>
            <li>
              Gỗ, thép: Được sử dụng để xây dựng kết cấu mái và các phần cần
              khung cấu trúc chịu lực khác.
            </li>
          </ol>
          <p className="bold">Xây Dựng Phần Hoàn Thiện:</p>
          <p>
            Sau khi hoàn thành phần thô, công việc tiếp theo là hoàn thiện các
            chi tiết nội ngoại thất của nhà. Các hạng mục thi công cụ thể bao
            gồm:
          </p>
          <ol style={{ paddingLeft: 50 }}>
            <li>
              Sơn phủ tường: Sơn lớp phủ bảo vệ và tạo màu sắc cho bề mặt tường.
            </li>
            <li>
              Lát nền, lát sàn: Làm bằng gạch, đá hoặc vật liệu lát khác tạo nên
              sàn nhà.
            </li>
            <li>
              Lắp đặt đèn, ổ điện: Lắp đặt hệ thống chiếu sáng và điện nước cho
              nhà.
            </li>
            <li>
              Lắp đặt nội thất: Gắn các thiết bị, đồ dùng nội thất như tủ bếp,
              tủ quần áo, đồ gỗ nội thất khác
            </li>
            <li>
              Hoàn thiện ngoại thất: Xây dựng hệ thống hàng rào, cổng, sân vườn,
              đường đi, v.v.
            </li>
          </ol>
          <p>
            Về vật liệu sử dụng trong xây dựng phần hoàn thiện, có thể bao gồm:
          </p>
          <ol style={{ paddingLeft: 50 }}>
            <li>
              Sơn, keo dán: Được sử dụng để hoàn thiện bề mặt tường, cửa, cửa
              sổ.
            </li>
            <li>Gạch, đá tự nhiên, gỗ laminate: Dùng để lát nền, lát sàn.</li>
            <li>Đèn, ổ cắm điện: Dùng để tạo ánh sáng và điện cho nhà.</li>
            <li>
              Gỗ, kim loại: Sử dụng để làm nội thất và ngoại thất của nhà.
            </li>
          </ol>
          <p>
            Trong quá trình hoàn thiện phần thô của công trình, chúng tôi cam
            kết sử dụng vật liệu xây dựng chất lượng cao và đúng chủng loại,
            thương hiệu đã được khách hàng xác nhận thông qua bảng dự toán vật
            tư chi tiết. Chúng tôi chỉ sử dụng những loại vật liệu xây dựng thô
            tốt nhất để đảm bảo chất lượng và sự bền vững của công trình.
          </p>
          <p>
            Các vật liệu xây dựng thô chuẩn thường sử dụng trong công trình bao
            gồm:
          </p>
          <ol style={{ paddingLeft: 50 }}>
            <li>
              Sắt thép Việt Nhật: Được sử dụng cho việc gia cố cấu trúc, tạo độ
              bền cho công trình.
            </li>
            <li>
              Gạch đỏ TUYNEL: Sử dụng để xây tường và các công trình xây dựng
              khác.
            </li>
            <li>Cát vàng: Dùng để đổ bê tông và xây tường.</li>
            <li>
              Đá xanh 1×2: Được sử dụng cho các công trình xây dựng và lát nền.
            </li>
            <li>
              Xi măng HOLCIM và xi măng Hà Tiên: Được sử dụng để trộn bê tông và
              xây dựng các cấu trúc khác.
            </li>
            <li>
              Dây điện Cadivi, dây cáp mạng và tivi: Sử dụng để lắp đặt hệ thống
              điện và mạng trong công trình.
            </li>
            <li>
              Ống luồn dây điện và ống nhựa Bình Minh: Dùng để đi dây điện và
              ống nước trong nhà.
            </li>
            <li>
              Chống thấm SIKA và KOVA: Được sử dụng để chống thấm cho các khu
              vực như nhà tắm, bếp.
            </li>
            <li>
              Tôn Hoa Sen: Sử dụng cho mái nhà và các công trình sắt thép.
            </li>
          </ol>
          <p>
            Trong quá trình thi công, nếu có yêu cầu thay đổi vật liệu thô sử
            dụng, chúng tôi sẽ thực hiện theo chỉ đạo của khách hàng. Tùy thuộc
            vào thương hiệu và sản phẩm thô được sử dụng, giá xây dựng có thể
            tăng hoặc giảm tương ứng. Chúng tôi luôn sẵn lòng hỗ trợ và tư vấn
            cho khách hàng nhằm đảm bảo sự hài lòng và hiệu quả cao nhất cho mọi
            dự án xây dựng.
          </p>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713597948/iwvtdksgcozwj1mczngf.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605401/kgmqeaskt1qfjt67i1bi.jpg"
              alt=""
            />
          </div>
          <p className="bold">Bảng đơn giá xây nhà phần thô:</p>
          <table className="quote-table">
            <tr>
              <th>STT</th>
              <th>Loại nhà</th>
              <th>Đơn vị</th>
              <th>Đơn giá xây nhà phần thô</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Nhà cấp 4</td>
              <td>vnđ/m2</td>
              <td>2.300.000 - 2.800.000</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Nhà phố</td>
              <td>vnđ/m2</td>
              <td>3.000.000 - 3.600.000</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Biệt thự</td>
              <td>vnđ/m2</td>
              <td>3.000.000 - 4.000.000</td>
            </tr>
          </table>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713779055/sek9hwpzzkt0gngvtui6.jpg"
              alt=""
            />
          </div>
          <p className="bold">Lưu Ý Quan Trọng:</p>
          <ul style={{ paddingLeft: "50px" }}>
            <li>
              Giá xây dựng có thể biến động tùy thuộc vào diện tích xây dựng, vị
              trí địa lý và loại vật liệu xây dựng sử dụng.
            </li>
            <li>
              Bảng giá đã bao gồm cả chi phí vật liệu xây dựng thô và lao động
              hoàn thiện, áp dụng cho các dự án tại Thành phố Hồ Chí Minh.
            </li>
            <li>
              Để tiến hành hoàn thiện công trình, quý khách hàng cần cung cấp
              các vật liệu xây dựng hoàn thiện theo yêu cầu cụ thể của dự án.
            </li>
          </ul>
          <p>
            Chúng tôi cam kết đảm bảo tính minh bạch và chính xác trong việc
            tính toán chi phí, đồng thời luôn sẵn lòng hỗ trợ và tư vấn cho quý
            khách hàng với mong muốn mang lại sự hài lòng và thành công cho mọi
            dự án xây dựng.
          </p>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713604730/ojfl6410fc4gjhlc2o97.jpg"
              alt=""
            />
          </div>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713604729/cncuf82zzpxr0kdkp2yp.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713604735/mwzefgochkuhvjgbtcwq.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default PhanTho;
