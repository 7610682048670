import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_SECRET_KEY;

export const isAuthenticated = () => {
  const encryptedUsername = sessionStorage.getItem("enUsN");
  return encryptedUsername ? true : false;
};

export const encryptedUserData = (res, data) => {
  const encryptedUser = CryptoJS.AES.encrypt(
    JSON.stringify({
      username: data.username,
      role: res.data.user.role,
    }),
    secretKey
  ).toString();
  return sessionStorage.setItem("enUsN", encryptedUser);
};

export const decryptedUserData = (encrypt) => {
  return CryptoJS.AES.decrypt(encrypt, secretKey).toString(CryptoJS.enc.Utf8);
};

export const getUserRole = () => {
  const encryptedUserData = sessionStorage.getItem("enUsN");
  if (encryptedUserData) {
    const decryptedUserData = CryptoJS.AES.decrypt(
      encryptedUserData,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const userData = JSON.parse(decryptedUserData);
    return userData.role;
  } else {
    return null;
  }
};

export const getUsername = () => {
  const encryptedUserData = sessionStorage.getItem("enUsN");
  if (encryptedUserData) {
    const decryptedUserData = CryptoJS.AES.decrypt(
      encryptedUserData,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const userData = JSON.parse(decryptedUserData);
    return userData.username;
  } else {
    return null;
  }
};

export const logout = () => {
  sessionStorage.removeItem("enUsN");
  window.location.href = "/login";
};
