import React from "react";
import { isAuthenticated } from "../../../utils/auth";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";

function AdminDashboard() {
  const isLoggedIn = isAuthenticated();

  return isLoggedIn ? (
    <AdminLayout>
      <div className="admin-container">
        <h1>Tổng quan</h1>
      </div>
    </AdminLayout>
  ) : (
    <ErrorPage />
  );
}

export default AdminDashboard;
