import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import "./PriceQuote.css";
import { Link } from "react-router-dom";

function PriceQuote() {
  return (
    <MainLayout>
      <div className="quote">
        <div className="container">
          <h1>Báo giá</h1>
          <div className="projects-cards">
            <Link to="/bao-gia/sua-chua">
              <div className="projects-card">
                <img
                  className="projects-card-img"
                  src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713607181/oyjvhi5bk5gelsh0mjjr.png"
                  alt=""
                />
                <div className="projects-card-name">
                  <p className="bold">Sữa chữa nhà</p>
                  <button className="btn">Xem chi tiết</button>
                </div>
              </div>
            </Link>
            <Link to="/bao-gia/tron-goi">
              <div className="projects-card">
                <img
                  className="projects-card-img"
                  src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713607181/q5cubuapsurxbhmkumv9.png"
                  alt=""
                />
                <div className="projects-card-name">
                  <p className="bold">Xây nhà trọn gói</p>
                  <button className="btn">Xem chi tiết</button>
                </div>
              </div>
            </Link>
            <Link to="/bao-gia/thiet-ke">
              <div className="projects-card">
                <img
                  className="projects-card-img"
                  src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713607180/hdugo2avfr4kipyjbvml.png"
                  alt=""
                />
                <div className="projects-card-name">
                  <p className="bold">Thiết kế, thi công</p>
                  <button className="btn">Xem chi tiết</button>
                </div>
              </div>
            </Link>
            <Link to="/bao-gia/phan-tho">
              <div className="projects-card">
                <img
                  className="projects-card-img"
                  src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713607180/rej76ffqspbuklz9ajla.png"
                  alt=""
                />
                <div className="projects-card-name">
                  <p className="bold">Phần thô</p>
                  <button className="btn">Xem chi tiết</button>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default PriceQuote;
