import React from "react";
import "./AdminHeader.css";
import { NavLink } from "react-router-dom";

function AdminHeader() {
  return (
    <header className="admin-header">
      <div className="admin-header-logo">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/snapedit_1713152251878%20(1).png?alt=media&token=2f6d13ef-4bac-4eaa-97b2-701fef150c9e"
          alt=""
        />
      </div>
      <div className="admin-header-nav">
        <NavLink to="/admin">Tổng quan</NavLink>
        <NavLink to="/admin/du-an">Dự án</NavLink>
        <NavLink to="/admin/tin-tuc">Tin tức</NavLink>
      </div>

      <div className="admin-user">Admin</div>
    </header>
  );
}

export default AdminHeader;
