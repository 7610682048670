import api from "./api";

const handleApiError = (error) => {
  console.error(error);
  throw new Error(error.response?.data?.error || "Lỗi!");
};

const login = async (userData) => {
  console.log(api);
  try {
    const response = await api.post("/api/users/login/", userData);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const getAllUser = async () => {
  try {
    const response = await api.get("/api/users");
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const deleteUser = async (id) => {
  try {
    const response = await api.delete(`/api/users/delete/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const getUserById = async (id) => {
  try {
    const response = await api.get(`/api/users/${id}`);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const changePassword = async (userId, newPassword) => {
  try {
    const response = await api.post(`/api/users/update/password/${userId}`, {
      newPwd: newPassword,
    });
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/api/users/update/${userId}`, userData);
    return response;
  } catch (error) {
    handleApiError(error);
  }
};

const userService = {
  login,
  getAllUser,
  deleteUser,
  getUserById,
  changePassword,
  updateUser,
};

export default userService;
