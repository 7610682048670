import React, { useState } from "react";
import { isAuthenticated } from "../../../utils/auth";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import projectService from "../../../services/project";
import api from "../../../services/api";
import Loading from "../../../components/Loading/Loading";

function AdminAddProject() {
  const isLoggedIn = isAuthenticated();
  const navigation = useNavigate();
  const [projectData, setProjectData] = useState({
    name: "",
    image: "",
    content: "",
  });
  const [loading, setLoading] = useState(false);

  const module = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      ["link", "blockquote", "code-block", "image"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleCancel = () => {
    navigation(-1);
  };

  const handleChange = (value) => {
    setProjectData((prevData) => ({
      ...prevData,
      content: value,
    }));
  };

  const findImageUrls = (text) => {
    const regex = /src\s*=\s*"([^"]+)"/g;
    const matches = text.match(regex) || [];

    return matches.map((match) => match.replace(/src\s*=\s*"([^"]+)"/, "$1"));
  };

  const uploadToCloudinary = async (imageUrl) => {
    try {
      let blob;
      if (imageUrl.startsWith("http")) {
        const response = await fetch(imageUrl);
        blob = await response.blob();
      } else {
        const byteCharacters = atob(imageUrl.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        blob = new Blob([byteArray], { type: "image/jpeg" });
      }
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");

      const response = await api.post("/api/upload", formData);
      const data = await response.data;

      return data.imageUrl;
    } catch (error) {
      console.error("Error uploading image to Cloudinary:", error);
      return imageUrl;
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      setProjectData((prevData) => ({
        ...prevData,
        image: event.target.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const imageUrls = findImageUrls(projectData.content);
      const newImageUrls = await Promise.all(imageUrls.map(uploadToCloudinary));

      let updatedContent = projectData.content;
      imageUrls.forEach((oldUrl, index) => {
        updatedContent = updatedContent.replace(oldUrl, newImageUrls[index]);
      });
      const updatedProjectData = {
        ...projectData,
        content: updatedContent,
      };
      await projectService.createProject(updatedProjectData);
      navigation("/admin/du-an");
    } catch (error) {
      console.error("Lỗi khi tạo mới dự án:", error);
    } finally {
      setLoading(false);
    }
  };

  return isLoggedIn ? (
    <AdminLayout>
      <LoadingWrapper loading={loading}>
        <div className="admin-container">
          <div className="admin-content">
            <div className="admin-content-header">
              <h1>Thêm dự án</h1>
              <div className="admin-content-form">
                <label>Tên dự án</label>
                <input
                  type="text"
                  className="input"
                  value={projectData.name}
                  onChange={(e) =>
                    setProjectData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
                <label>Ảnh dự án</label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  onChange={handleImageUpload}
                />
                {projectData.image && (
                  <img
                    src={projectData.image}
                    alt="Project"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                <label>Nội dung</label>
                <ReactQuill
                  modules={module}
                  theme="snow"
                  value={projectData.content}
                  onChange={handleChange}
                />
                <div className="admin-content-form-function">
                  <button className="btn" onClick={handleCancel}>
                    Huỷ
                  </button>
                  <button className="btn" onClick={handleSubmit}>
                    Tạo mới dự án
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingWrapper>
    </AdminLayout>
  ) : (
    <ErrorPage />
  );
}

function LoadingWrapper({ loading, children }) {
  return loading ? <Loading /> : children;
}

export default AdminAddProject;
