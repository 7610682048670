import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout/AdminLayout";
import { isAuthenticated } from "../../../utils/auth";
import newsService from "../../../services/news";
import ErrorPage from "../../../pages/ErrorPage/ErrorPage";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";

function AdminNews() {
  const isLoggedIn = isAuthenticated();

  const [listNews, setListNews] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await newsService.getAllNews();
        setListNews(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [listNews]);

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = listNews.slice(indexOfFirstNews, indexOfLastNews);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const filteredNews = currentNews.filter((n) =>
    n.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return isLoggedIn ? (
    <AdminLayout>
      <div className="admin-container">
        <div className="admin-content">
          <div className="admin-content-header">
            <h1>Tin tức</h1>
            <Link to="/admin/tin-tuc/them">
              <button className="btn">Tạo mới</button>
            </Link>
          </div>
          <input
            type="text"
            placeholder="Tìm kiếm dự án..."
            onChange={handleSearch}
            className="searchInData"
          />
          <table style={{ padding: "10px" }}>
            <DataTable data={filteredNews} type="tintuc" />
          </table>
          <ul className="pagination">
            {Array.from({
              length: Math.ceil(listNews.length / newsPerPage),
            }).map((_, index) => (
              <li key={index} className="page-item">
                <button
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AdminLayout>
  ) : (
    <ErrorPage />
  );
}

export default AdminNews;
