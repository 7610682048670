import React from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

function TronGoi() {
  return (
    <MainLayout>
      <div className="quote">
        <div className="container">
          <h1>Xây nhà trọn gói</h1>
          <p className="bold">
            Xây Nhà Trọn Gói: Dịch Vụ Hoàn Hảo Cho Ngôi Nhà Mơ Ước
          </p>
          <p>Khái Niệm và Ưu Điểm của Xây Nhà Trọn Gói:</p>
          <p>
            Xây nhà trọn gói, hay còn được gọi là dịch vụ xây nhà chìa khóa trao
            tay, là sự lựa chọn đỉnh cao dành cho những chủ đầu tư muốn tiếp cận
            ngôi nhà mơ ước mà không phải lo lắng về các công đoạn chi tiết.
            Trong dịch vụ này, chủ đầu tư chỉ cần cung cấp nguồn kinh phí cần
            thiết, còn lại mọi công việc từ thiết kế đến hoàn thiện sẽ được Công
            ty Xây Dựng Quân Bảo đảm nhiệm hoàn toàn. Đây không chỉ là cách tiếp
            cận thông minh mà còn là giải pháp tiết kiệm thời gian và công sức
            cho chủ đầu tư.
          </p>
          <p className="bold">Các Dịch Vụ Cụ Thể của Xây Nhà Trọn Gói:</p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>
              Tư Vấn Trước Xây: Chúng tôi sẽ hỗ trợ khách hàng trong việc lập kế
              hoạch, từ bố trí mặt bằng đến dự toán tài chính, cũng như các thủ
              tục pháp lý cần thiết và lên lịch trình thi công.
            </li>
            <li>
              Thiết Kế Nhà: Đảm bảo sự tiện nghi và phù hợp với nhu cầu của
              khách hàng, việc thiết kế nhà được thực hiện một cách chuyên
              nghiệp và tỉ mỉ.
            </li>
            <li>
              Hoàn Tất Thủ Tục Pháp Lý: Từ bản vẽ đến hồ sơ xin giấy phép xây
              dựng, chúng tôi sẽ xử lý mọi thủ tục cần thiết để đảm bảo quá
              trình xây dựng diễn ra suôn sẻ.
            </li>
            <li>
              Báo Giá Chi Tiết: Dựa trên bản vẽ thiết kế và giấy phép xây dựng,
              chúng tôi sẽ lập báo giá chi tiết, đảm bảo tính chính xác và minh
              bạch trong mọi giao dịch.
            </li>
            <li>
              Thi Công Xây Dựng: Từ việc tháo dỡ nhà cũ đến hoàn thiện nội ngoại
              thất, chúng tôi sẽ tiến hành mọi công đoạn với sự chuyên nghiệp và
              tận tâm.
            </li>
            <li>
              Hoàn Thiện Nội Thất: Với bản vẽ thiết kế nội thất chi tiết, chúng
              tôi sẽ đảm bảo mọi công việc hoàn thiện nội thất đạt được chuẩn
              mực cao nhất.
            </li>
          </ol>
          <p className="bold">
            Các công việc trong phạm vi xây dựng trọn gói bao gồm:
          </p>
          <ol>
            <li>
              Tháo Dỡ và Chuẩn Bị Mặt Bằng: Loại bỏ nhà cũ, chuẩn bị mặt bằng
              xây dựng và làm vệ sinh kỹ thuật mặt bằng.
            </li>
            <li>
              Đào Móng: Thực hiện các công việc đào móng để chuẩn bị cho quá
              trình xây dựng móng nhà.
            </li>
            <li>
              Xây Dựng Cơ Bản: Bao gồm việc xây dựng móng, tường, sàn nhà và sàn
              mái.
            </li>
            <li>
              Lắp Đặt Hệ Thống Thoát Nước Thải: Bao gồm việc xây dựng và lắp đặt
              các hệ thống thoát nước thải để đảm bảo tiêu chuẩn vệ sinh cho
              công trình.
            </li>
            <li>
              Xây Cầu Thang và Ngăn Chia Phòng: Xây dựng và lắp đặt các cầu
              thang và ngăn chia phòng theo yêu cầu thiết kế.
            </li>
            <li>
              Tô Tường và Chống Thấm: Hoàn thiện bề mặt tường bằng việc tô tường
              và thực hiện công việc chống thấm cho sàn mái, sàn ban công và nhà
              vệ sinh.
            </li>
            <li>
              Đóng Trần Thạch Cao và Trét Bả Matit: Lắp đặt và hoàn thiện trần
              thạch cao cũng như thực hiện việc trét bả matit trên các bề mặt
              cần thiết.
            </li>
            <li>
              Công Tác Ốp Gạch và Ốp Đá: Làm việc với các vật liệu như gạch và
              đá để ốp lát nền, ốp lát nhà vệ sinh và ban công.
            </li>
            <li>
              Lắp Đặt Cửa và Thiết Bị Vệ Sinh: Gia công và lắp đặt toàn bộ cửa
              đi, cửa sổ và các thiết bị vệ sinh như bồn cầu, lavabo, vòi sen và
              bồn nước.
            </li>
            <li>
              Lắp Đặt Hệ Thống Điện và Đèn Chiếu Sáng: Bao gồm việc lắp đặt ổ
              cắm, công tắc, đèn cầu thang, đèn downlight, dây mạng và dây
              truyền hình.
            </li>
            <li>
              Đảm Bảo Sạch Sẽ và An Ninh Công Trình: Bảo đảm rằng công trình
              luôn sạch sẽ và an toàn trong suốt quá trình xây dựng, kèm theo sự
              giám sát của kĩ sư và cung cấp các biện pháp bảo vệ.
            </li>
            <li>
              Hoàn Thiện Nội Thất: Đảm bảo việc hoàn thiện nội thất với bản vẽ
              thiết kế nội thất được thỏa thuận trong hợp đồng riêng biệt.
            </li>
          </ol>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713779588/zrdldi85ewzahh2on6uv.png"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713779588/ail9qbowt5ddxkx5cmwc.jpg"
              alt=""
            />
          </div>
          <p className="bold">Lợi Ích của Dịch Vụ Xây Nhà Trọn Gói:</p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>
              Tiết Kiệm Chi Phí: Dịch vụ này không chỉ giúp khách hàng tiết kiệm
              chi phí xây dựng mà còn giảm bớt những rủi ro và chi phí phát sinh
              do biến động thị trường.
            </li>
            <li>
              Tiết Kiệm Thời Gian: Với sự chuyên môn và kinh nghiệm, chúng tôi
              đảm bảo tiến độ xây dựng được đề xuất và không gặp trục trặc giữa
              các công đoạn.
            </li>
            <li>
              An Toàn và Thẩm Mỹ: Chúng tôi cam kết đảm bảo an toàn cho công
              trình và cung cấp giải pháp thiết kế thẩm mỹ, tối ưu không gian sử
              dụng.
            </li>
            <li>
              Chuyên Môn và Bảo Hành: Với đội ngũ kỹ thuật lành nghề, chúng tôi
              đảm bảo chất lượng công trình và cung cấp chế độ bảo hành sau hoàn
              thiện.
            </li>
          </ol>
          <p className="bold">
            Xây Nhà Trọn Gói: Sự Lựa Chọn Tối Ưu cho Ngôi Nhà Hoàn Hảo
          </p>
          <p>
            Nếu bạn đang tìm kiếm một giải pháp đáng tin cậy và hiệu quả cho dự
            án xây dựng của mình, dịch vụ xây nhà trọn gói của Công ty Xây Dựng
            Quân Bảo sẽ là lựa chọn hàng đầu. Hãy để chúng tôi chia sẻ gánh nặng
            của quá trình xây dựng và mang đến cho bạn ngôi nhà mơ ước hoàn hảo
            nhất.
          </p>
          <p>
            Tính đơn giá xây nhà trọn gói được thực hiện thông thường thông qua
            hai phương pháp chính. Để có kết quả chính xác và đáng tin cậy, bạn
            có thể sử dụng phương pháp tính toán theo bảng dự toán. Cách thứ hai
            là tính theo m2 sử dụng hoặc m2 mái bê tông.
          </p>
          <p className="bold">Tính đơn giá xây nhà trọn gói theo m2</p>
          <p>
            Phương pháp này cho phép bạn ước lượng giá cả dựa trên diện tích mặt
            bằng nhà và mẫu hoặc hình ảnh 3D có sẵn. Tuy nhiên, cách tính này
            không luôn rõ ràng và có thể gây ra những bất tiện trong quá trình
            thi công do thiếu sự cụ thể. Do đó, nên sử dụng cách tính này chỉ để
            dự trù chi phí và không nên dựa vào nó để ký kết hợp đồng thi công
            trọn gói.
          </p>
          <p>
            Ở Quân Bảo, sau khi thực hiện khảo sát và hiểu rõ nhu cầu của khách
            hàng, chúng tôi sẽ cung cấp báo giá khái toán theo m2 để giúp khách
            hàng dự trù chi phí ban đầu và tiến hành hợp đồng thiết kế.
          </p>
          <p className="bold">
            Dưới đây là đơn giá xây nhà trọn gói theo m2 tại Quân Bảo để bạn
            tham khảo:
          </p>
          <table className="quote-table">
            <tr>
              <th>Gói phổ thông (vnđ/m2)</th>
              <th>Gói khá (vnđ/m2)</th>
              <th>Gói cao cấp (vnđ/m2)</th>
            </tr>
            <tr>
              <td>4.500.000 - 5.000.000</td>
              <td>5.000.000 - 5.500.000</td>
              <td>5.500.000 - 6.000.000</td>
            </tr>
          </table>
          <p className="bold">Ghi chú:</p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>
              Đơn giá xây nhà trọn gói phụ thuộc vào diện tích xây dựng và khu
              vực.
            </li>
            <li>
              Đơn giá có thể thay đổi tùy thuộc vào giá vật liệu xây dựng.
            </li>
            <li>Miễn phí hồ sơ xây dựng và giấy phép xây dựng.</li>
          </ol>
          <p className="bold">
            Tính giá thi công xây nhà trọn gói theo dự toán
          </p>
          <p>
            Phương pháp này là việc lập dự toán từ việc bóc tách khối lượng dựa
            trên bản vẽ thiết kế. Đây là phương pháp chính xác và cung cấp bảng
            dự toán chi tiết với số liệu gần như chính xác hoàn toàn, giúp tránh
            phát sinh chi phí không mong muốn trong quá trình thi công.
          </p>
          <p>
            Tại Quân Bảo, chúng tôi cung cấp tư vấn kỹ lưỡng và đầy đủ về các
            hạng mục cần thi công, từ đó tạo ra bảng dự toán chi tiết nhất. Mọi
            thỏa thuận và hạng mục thi công đều được ghi rõ trong hợp đồng ký
            kết giữa hai bên.
          </p>
          <p>
            Trên thực tế, việc sử dụng dịch vụ xây nhà trọn gói là lựa chọn
            thông minh, đặc biệt đối với những chủ đầu tư không có thời gian và
            kinh nghiệm quản lý vật tư, giúp tránh kéo dài thời gian thi công và
            đảm bảo chất lượng công trình.
          </p>
          <p className="bold">Tại sao nên chọn Quân Bảo để xây nhà trọn gói</p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>
              Uy tín: Chúng tôi xây dựng và phát triển uy tín qua thời gian bằng
              cách luôn tuân thủ cam kết một cách nghiêm túc và đáng tin cậy.
            </li>
            <li>
              Thiết kế độc đáo: Mỗi thiết kế của chúng tôi đều mang đặc điểm
              riêng biệt, tạo ấn tượng sâu sắc và độc đáo cho từng gia đình.
              Chúng tôi đảm bảo tính hiện đại và đầy đủ các tiện ích trong mỗi
              mẫu thiết kế.
            </li>
            <li>
              Thi công đúng tiến độ: Chúng tôi cam kết thực hiện thi công và xây
              dựng đúng tiến độ, giúp khách hàng nhận nhà sớm nhất có thể để
              tiết kiệm thời gian và chi phí. Chất lượng công trình luôn được
              đảm bảo từng bước thực hiện.
            </li>
            <li>
              Xây nhà trọn gói: Dịch vụ xây nhà trọn gói của chúng tôi bao gồm
              tất cả từ A đến Z, đảm bảo về nguồn vật liệu và chất lượng thi
              công, tuân thủ đúng cam kết và kỹ thuật. Điều này giúp khách hàng
              hoàn toàn yên tâm với dịch vụ "xây nhà trọn gói - chìa khóa trao
              tay".
            </li>
            <li>
              Chuyên nghiệp: Sự chuyên nghiệp của chúng tôi được thể hiện qua sự
              minh bạch và rõ ràng trong bảng báo giá và quy trình làm việc.
            </li>
          </ol>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713604052/xup0bz7rzbq9snqydbrl.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713604049/iqwenwnjy3yimioddbfb.jpg"
              alt=""
            />
          </div>
          <p className="bold">
            Quy trình làm việc của Quân Bảo trong dịch vụ xây nhà trọn gói:
          </p>
          <p className="bold">Bước 1: Thu thập thông tin</p>
          <p>
            Chúng tôi tiếp nhận thông tin cơ bản về địa điểm thi công, diện tích
            đất, quy mô xây dựng và thông tin cá nhân của khách hàng.
          </p>
          <p className="bold">Bước 2: Báo giá khái toán theo m2</p>
          <p>
            Dựa trên thông tin thu thập và nhu cầu xây dựng ban đầu, chúng tôi
            gửi lại báo giá khái toán để khách hàng có cơ sở dự trù chi phí ban
            đầu cho việc triển khai hợp đồng thiết kế.
          </p>
          <div className="bold">
            Bước 3: Kí hợp đồng thiết kế và tiến hành xin giấy phép xây dựng
          </div>
          <p>
            Sau khi khách hàng đồng ý, chúng tôi kí hợp đồng thiết kế và tiến
            hành tạm ứng chi phí thiết kế. Quá trình này bao gồm ghi nhận rõ
            ràng nhu cầu cụ thể của khách hàng, lập concept mặt bằng và điều
            chỉnh theo phản hồi và tư vấn từ chuyên viên kiến trúc của chúng
            tôi. Sau đó, chúng tôi tiến hành xin giấy phép xây dựng từ cơ quan
            có thẩm quyền.
          </p>
          <p className="bold">Bước 4: Hoàn thiện và bàn giao hồ sơ thiết kế</p>
          <p>
            Trong thời gian chờ giấy phép (thông thường 14 – 21 ngày), bộ phận
            thiết kế tiếp tục hoàn thiện các phần còn lại của hồ sơ thiết kế bao
            gồm: Kiến Trúc, Kết Cấu, Điện Nước. Gia đình tiếp tục chỉnh sửa và
            thống nhất hồ sơ thiết kế hoàn chỉnh để chuẩn bị cho quá trình thi
            công.
          </p>
          <p>
            Đội ngũ kỹ sư của Quân Bảo cùng với chủ đầu tư kiểm tra thiết kế
            theo giấy phép xây dựng chính thức và bàn giao hồ sơ thiết kế hoàn
            chỉnh cho Chủ đầu tư. Quá trình này đánh dấu sự hoàn tất của quy
            trình thiết kế và chuẩn bị cho giai đoạn thi công.
          </p>
          <p className="bold">
            Bước 5: Bốc tách khối lượng và lập báo giá dự toán chi tiết
          </p>
          <p>
            Dựa trên hồ sơ thiết kế hoàn chỉnh, các kỹ sư bốc tách khối lượng và
            lập báo giá dự toán chi tiết, bao gồm thông tin về chủng loại vật
            tư, đơn giá, thành tiền, kích thước, xuất sứ và các chi phí khác.
            Tổng chi phí chính xác này sẽ là cơ sở để chủ đầu tư quyết định kí
            hợp đồng thi công.
          </p>
          <p className="bold">
            Bước 6: Thương thảo và ký kết hợp đồng xây dựng
          </p>
          <p>
            Sau khi khách hàng duyệt bảng báo giá, hai bên sẽ thảo luận và ký
            kết hợp đồng xây dựng với các điều khoản và ràng buộc cụ thể.
          </p>
          <p className="bold">Bước 7: Thực hiện hợp đồng</p>
          <p>
            Chúng tôi sẽ thực hiện dự án xây dựng theo đúng với hợp đồng đã thỏa
            thuận, với sự giám sát của kỹ sư trong suốt quá trình thi công.
          </p>
          <p className="bold">Bước 8: Nghiệm thu công trình</p>
          <p>
            Chủ đầu tư cùng với nhà thầu sẽ tiến hành kiểm tra và nghiệm thu
            công trình căn cứ trên hợp đồng ký kết và bản vẽ thiết kế được phê
            duyệt.
          </p>
          <p className="bold">
            Bước 9: Thanh lý hợp đồng và bảo hành công trình
          </p>
          <div className="image-fuild">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713605401/qgi6bolcyzm66bd9kryc.jpg"
              alt=""
            />
          </div>
          <p className="bold">
            Cam kết Chất Lượng Dịch Vụ Xây Nhà Trọn Gói từ Quân Bảo
          </p>
          <ol style={{ paddingLeft: "30px" }}>
            <li>
              Sử dụng Vật Liệu Chất Lượng: Chúng tôi cam kết sử dụng các vật
              liệu xây dựng chính hãng và tuân thủ đúng thỏa thuận trong hợp
              đồng thi công.
            </li>
            <li>
              Cam Kết Chất Lượng: Đảm bảo thi công đúng quy định và tiêu chuẩn
              xây dựng. Kiểm tra nghiệm thu kỹ lưỡng từng hạng mục trước khi
              tiến hành công việc tiếp theo và duy trì bảo dưỡng công trình suốt
              quá trình thi công.
            </li>
            <li>
              Không Bán Thầu: Các công trình được thực hiện bởi đội ngũ của
              chúng tôi mà không thông qua bên thứ ba. Chúng tôi tập trung vào
              chất lượng bằng cách phát triển đội ngũ thi công riêng cho từng
              hạng mục và giám sát chặt chẽ bởi các chuyên gia kỹ thuật.
            </li>
            <li>
              Không Lãng Phí: Quản lý chặt chẽ nguồn vật liệu và thiết bị thi
              công theo tiêu chuẩn và quy định, cam kết không phát sinh lãng phí
              đối với vật tư khách hàng cung cấp.
            </li>
            <li>
              Không Tăng Giá: Đảm bảo giữ nguyên giá xây dựng từ thời điểm kí
              kết hợp đồng cho đến khi hoàn thành công trình, không tăng giá
              ngay cả khi có biến động về giá vật liệu sau khi bắt đầu công
              trình.
            </li>
            <li>
              Bảo Đảm Đúng Tiến Độ: Kiểm soát tiến độ hàng ngày và hàng tuần
              thông qua bảng tiến độ tổng, đảm bảo cung cấp vật liệu đúng hạn và
              ghi nhận tiến độ công trình mỗi ngày.
            </li>
            <li>
              An Toàn Kỹ Thuật và Lao Động: Thi công luôn tuân thủ quy trình và
              yêu cầu kỹ thuật an toàn, đảm bảo mọi hoạt động diễn ra dưới sự
              giám sát của các chuyên gia có kinh nghiệm và tuân thủ nghiêm ngặt
              các quy định an toàn lao động.
            </li>
            <li>
              Pháp Lý: Có hợp đồng thi công đầy đủ và chịu trách nhiệm pháp lý
              đối với mọi công trình. Tất cả hoạt động được thực hiện dưới sự
              đăng ký kinh doanh và với sự tham gia của các kỹ sư có bằng cấp và
              đào tạo chuyên sâu.
            </li>
          </ol>
          <p>
            Để có giải pháp phù hợp và chi phí tối ưu, biến ước mơ xây dựng ngôi
            nhà an toàn, thẩm mỹ và chất lượng thành hiện thực, Quân Bảo luôn
            sẵn lòng khảo sát, tư vấn và lên dự toán chi tiết cho mọi dự án xây
            dựng. Hãy liên hệ với chúng tôi ngay để nhận được sự tư vấn tận tâm
            và chuyên nghiệp nhất.
          </p>
          <p>Thông tin chi tiết liên hệ: 0974281309</p>
          <div className="image-f">
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713778419/fgd2ikrededhnjhaf3vd.jpg"
              alt=""
            />
            <img
              src="https://res.cloudinary.com/dv0zeng2j/image/upload/v1713778390/miharsbuvbu834hojhtv.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default TronGoi;
