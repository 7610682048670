import React, { useEffect, useState } from "react";
import "./Projects.css";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import projectService from "../../services/project";
import { Link } from "react-router-dom";

function Projects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await projectService.getAllProjects();
      setProjects(response.data);
    };
    fetchData();
  }, [projects]);

  return (
    <MainLayout>
      <div className="projects">
        <div className="container">
          <h1>Các dự án đã thực hiện</h1>
          <div className="projects-cards">
            {projects.map((p, i) => (
              <Link to={`/du-an/${p._id}`}>
                <div className="projects-card">
                  <img className="projects-card-img" src={p.image} alt="" />
                  <div className="projects-card-name">
                    <p className="bold">{p.name}</p>
                    <button className="btn">Xem chi tiết</button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Projects;
