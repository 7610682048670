import React from "react";
import projectService from "../../../services/project";
import newsService from "../../../services/news";

function DataTable({ data, type }) {
  const renderHeader = () => {
    if (type === "duan") {
      return (
        <tr>
          <th style={{ width: "20%" }}>Tên dự án</th>
          <th style={{ width: "60%" }}>Nội dung</th>
          <th style={{ width: "20%" }}></th>
        </tr>
      );
    }
    if (type === "tintuc") {
      return (
        <tr>
          <th style={{ width: "20%" }}>Tên tin tức</th>
          <th style={{ width: "60%" }}>Nội dung</th>
          <th style={{ width: "20%" }}></th>
        </tr>
      );
    }
  };

  const renderBody = () => {
    if (type === "duan") {
      return (
        <>
          {data.map((d, i) => (
            <tr key={i}>
              <td style={{ width: "20%" }}>{d.name}</td>
              <td style={{ width: "70%" }}>{d.content}</td>
              <td style={{ width: "10%" }}>
                <button
                  style={{ backgroundColor: "#fafafa" }}
                  onClick={() => projectService.deleteProject(d._id)}
                >
                  Xoá
                </button>
              </td>
            </tr>
          ))}
        </>
      );
    }
    if (type === "tintuc") {
      return (
        <>
          {data.map((d, i) => (
            <tr key={i}>
              <td style={{ width: "20%" }}>{d.name}</td>
              <td style={{ width: "70%" }}>{d.content}</td>
              <td style={{ width: "10%" }}>
                <button
                  style={{ backgroundColor: "#fafafa" }}
                  onClick={() => newsService.deleteNews(d._id)}
                >
                  Xoá
                </button>
              </td>
            </tr>
          ))}
        </>
      );
    }
  };

  return (
    <>
      {renderHeader()}
      {renderBody()}
    </>
  );
}

export default DataTable;
