import React from "react";
import "./Footer.css";
import { CiFacebook } from "react-icons/ci";
import { SiZalo } from "react-icons/si";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer-grid">
            <div className="footer-grid-item">
              <span>CÔNG TY TNHH ĐẦU TƯ XÂY DỰNG QUÂN BẢO</span>
              <p>
                Địa chỉ: Số 451/30 đường Xô Viết Nghệ Tĩnh, Phường 26, Quận Bình
                Thạnh, Thành phố Hồ Chí Minh.
              </p>
              <p>Số điện thoại: 0974281309</p>
              <p>Email: xaydungquanbao@gmail.com</p>
            </div>
            <div className="footer-grid-item">
              <span>Liên kết mạng xã hội</span>
              <i
                style={{
                  justifyContent: "start",
                  padding: "10px 10px",
                }}
              >
                <Link
                  to="https://www.facebook.com/profile.php?id=100091774462739"
                  target="_blank"
                  style={{ color: "#fff" }}
                >
                  <CiFacebook />
                </Link>
                <Link
                  to="https://zalo.me/0974281309"
                  target="_blank"
                  style={{ color: "#fff", marginLeft: 10 }}
                >
                  <SiZalo />
                </Link>
              </i>
            </div>
            <div className="footer-grid-item">
              <span>Thông tin tư vấn</span>
              <p>Liên hệ để biết thêm thông tin</p>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="container">
          Quân Bảo 2024&copy;. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default Footer;
