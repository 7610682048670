import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div className="error-page">
      <div className="error-page-container">
        <img
          src="https://cdn.svgator.com/images/2022/01/404-page-animation-example.gif"
          alt=""
        />
        <p>Lỗi trang này không tồn tại hoặc bạn không có quyền để truy cập.</p>
        <Link to="/">Quay lại trang chủ</Link>
      </div>
    </div>
  );
}

export default ErrorPage;
