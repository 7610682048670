import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

function Header() {
  const [navOpen, setNavOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setNavOpen(false);
      } else {
        setNavOpen(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNavOpen = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="container">
          <div className="header-wrapper">
            <div className="header-logo">
              <Link to="/">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/quanbao-e72a8.appspot.com/o/snapedit_1713152251878%20(1).png?alt=media&token=2f6d13ef-4bac-4eaa-97b2-701fef150c9e"
                  alt=""
                />
              </Link>
            </div>
            <div className="header-contact">
              <div className="header-contact-slogan">
                Uy tín vững chắc - Xây dựng đẳng cấp
              </div>
              <div className="header-contact-phone">
                <Link to="tel:+84974281309">Điện thoại: 0974281309</Link>
              </div>
            </div>
            <div className="header-location">
              <div className="header-location-company">
                CÔNG TY TNHH ĐẦU TƯ XÂY DỰNG QUÂN BẢO
              </div>
              <div className="header-location-address">
                Số 451/30 đường Xô Viết Nghệ Tĩnh, Phường 26, Quận Bình Thạnh,
                Thành phố Hồ Chí Minh
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-hamburger" onClick={toggleNavOpen}>
            <i>
              <RxHamburgerMenu />
            </i>
          </div>
          <div
            className="header-nav"
            style={{ display: navOpen ? "flex" : "none" }}
          >
            <NavLink to="/gioi-thieu">Giới thiệu</NavLink>
            <NavLink to="/bao-gia">Báo giá</NavLink>
            <NavLink to="/du-an">Dự án</NavLink>
            <NavLink to="/tin-tuc">Tin tức</NavLink>
            <NavLink to="/lien-he">Liên hệ</NavLink>
            <NavLink to="/login">Đăng nhập</NavLink>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
